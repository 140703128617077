
import React from 'react';
import './gk-time-counter.scss';
import Config from '../../services/config';

let hoursLabel;
let minutesLabel;
let secondsLabel;

let totalSeconds;

export class GkTimeCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.render();

        setTimeout(() => {
            totalSeconds = 0;
            hoursLabel = document.getElementById("hours");
            minutesLabel = document.getElementById("minutes");
            secondsLabel = document.getElementById("seconds");
        });

        this.timeInterval = setInterval(() => {
            this.setTime();
        }, 1000);

    }

    // Fired componenet destroy
    componentWillUnmount() {
        totalSeconds = 0;
        clearInterval(this.timeInterval);
    }

    render() {
        return (
            <p className="gk-timer-container">
                <img className="red-dot" src={`${Config.ImagesPath}red_dot.svg`} alt="img" />
                <span className="rec">Rec</span>
                <span className="time">
                    <label id="hours">00</label>:<label id="minutes">00</label>:<label id="seconds">00</label>
                </span>
            </p>
        );
    }



    setTime = () => {
        ++totalSeconds;
        secondsLabel.innerHTML = this.pad(totalSeconds % 60);
        minutesLabel.innerHTML = this.pad(parseInt(totalSeconds / 60));
        hoursLabel.innerHTML = this.pad(parseInt(totalSeconds % 60 / 60));
    }

    pad = (val) => {
        var valString = val + "";
        if (valString.length < 2) {
            return "0" + valString;
        } else {
            return valString;
        }
    }
}