import React, { Component } from 'react';
import './gkLogo.scss';
import Config from '../../services/config';
import GkLinearLoader from '../GkLinearLoader/linearLoader';

export class GkLogo extends Component {
    render() {
        return (
            <div className="gklogo-container">
                <img src={`${Config.ImagesPath}lite-keeper-logo.svg`} className="logo" alt="logo" />
                <GkLinearLoader />
            </div>
        )
    }
}