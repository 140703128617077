import axios from 'axios';
import Config from './config';

const WebApiService = {

    // Implement AxiosRequestConfig as options obj
    makeRequest: (url, data, method, headers = null, withCredentials = true) => {
        if (!method) return console.error('Please provice a method!');
        let reqOptions = {
            body: data,
            withCredentials
        };

        // validate token
        if (!axios.defaults.headers.common['Authorization']) return;

        switch (method) {
            case 'GET':
                return axios.get(Config.API_URL + url, reqOptions);
            case 'POST':
                return axios.post(Config.API_URL + url, data, reqOptions);
            case 'DELETE':
                return axios.delete(Config.API_URL + url, reqOptions);
            case 'PUT':
                return axios.put(Config.API_URL + url, data, reqOptions);
            default:
                return null;
        }
    },
    setToken: (token) => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log('token has been set');
    }
}

export default WebApiService;