
import React from 'react';
import './gk-messages-bar.scss'

import { Grid } from '@material-ui/core';
import Config from '../../services/config';
import { GkTimeCounter } from '../GkTimeCounter/gk-time-counter.component';
import { GkSpinner } from '../GkSpinner/gk-spinner.component';

let connectingMessage = [];
let streamingMessage = [];
// let errorMessage = [];
let connectionLostMessage = [];

export class GkMessagesBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.setMessagesTemplates();
        this.render();
    }

    render() {
        return (
            <Grid className="messages-container" container direction="row">
                {this.getMessage()}
            </Grid>
        );
    }

    setMessagesTemplates = () => {
        connectingMessage =
            [<GkSpinner key="1" />,
            // to {this.props.organiztionName || 'Europol'}
            <p key="2" className="row">Connecting </p>
            ];

        streamingMessage = [
            // to {this.props.organiztionName || 'Europol'}
            <p key="3" className="row">Streaming Video </p>,
            <GkTimeCounter key="4" />
        ];

        connectionLostMessage = [
            <img key="5" src={`${Config.ImagesPath}error.svg`} alt="img" />,
            <p key="6" className="row">Connection Lost</p>,
            <p key="7" className="row last-row">      Retry</p>
        ];

        // errorMessage = [
        //     <img key="8" src={`${Config.ImagesPath}error.svg`} alt="img" />,
        //     <p key="9" className="row">Oops, something went wrong</p>
        // ];
    }

    getMessage = () => {
        if (this.props.isLoading) return connectingMessage;
        if (this.props.isStreaming) return streamingMessage;
        if (this.props.errorType === Config.ErrorTypes.Connection_Disconnected) return connectionLostMessage;
    }
}