import React from "react";
import Config from "../../services/config";

export class GkPowered extends React.Component {
    render() {
        return (
            <div className="powered-container">
                <img src={`${Config.ImagesPath}powerd_by@2x.png`} className="powered" alt="logo" />
            </div>
        );
    }
}

