import React, { Component } from 'react';
import './App.scss';

import WebApiService from './services/web-api.service';
import Config from './services/config';

import { GkLocationStatus } from './components/GkLocationStatus/gk-location-status.component';
import { GkLogo } from './components/GkLogo/gkLogo.component';
import { GkPowered } from './components/GkPowered/gkPowered.component'
import { GkStreamView } from './components/GkStreamView/gk-stream-view.component';
import { GkMessagesBar } from './components/GkMessagesBar/gk-messages-bar.component';
import { GkErrorPage } from './components/gkErrorPage/gkErrorPage.component';
import {
  isIOS,
  browserName
} from 'react-device-detect';
class App extends Component {
  // Valid constructor with a super call();
  // can be inited with props as parameter
  constructor(props) {
    super(props);
    this.locationComponent = React.createRef();
    this.state = {
      validate: false,
      appLoading: true,
      isLoading: false,
      error: null,
      entity: null,
      userLocation: null,
      isStreaming: false
    };
    // Update position then init child components
    this.updatePosition().then(() => {
      this.getAndSetTokenFromUrl().then(() => {
        // Finish loading
        this.setState({ appLoading: false });
        this.watchLocation();
        // Bind this here for enabling components update app state
        this.locationComponent.current.sendStatus().then((res) => {
          console.log('res from location component after status update: ', res)
          this.setState({
            entity: res.data,
            validate: true
          });
        }, () => {
          console.error('Login Failed!!!');
          this.setState({
            error: Config.ErrorTypes.Bad_Token
          });
        });
      }, (e) => {
        this.setState({
          error: Config.ErrorTypes.Bad_Token,
          appLoading: false
        });
      });
    }, () => {
      this.setState({
        error: Config.ErrorTypes.Location_Permissions,
        appLoading: false
      })
    });

  }

  // Main and start app render function
  render = () => {
    return (
      <div className="lite-keeper-container" style={{ textAlign: "center" }}>
        {isIOS && browserName === 'Chrome' ? <GkErrorPage errorType={Config.ErrorTypes.Chrome_IOS_ERROR} /> : <div>
          {/* Logo and Loader */}
          {this.state.appLoading ? <GkLogo className="logo-component" /> :
            <div className="in-app-container">
              {/* Top messages Component */}
              {this.state.validate ? <GkMessagesBar isStreaming={this.state.isStreaming} errorType={this.state.error} organiztionName={this.state.entity ? this.state.entity.organiztionName : null} isLoading={this.state.isLoading} /> : null}
              {/* Status UPDATE Component  */}
              {this.state.userLocation ? <GkLocationStatus isStreaming={this.state.isStreaming}
                userLocation={this.state.userLocation}
                ref={this.locationComponent} /> : null}

              {/* Streaming buttons */}
              {this.state.validate ? <GkStreamView entity={this.state.entity} updateAppStreamStatus={this.updateStreamingStatus} userLocation={this.state.userLocation} error={this.state.error} /> : null}
              {/* Error Handling */}
              {this.state.error !== null ? <GkErrorPage errorType={this.state.error} /> : null}

            </div>}
        </div>}
        {/* Powered By GlobeKeeper */}
        <GkPowered />
      </div>

    );
  }

  // Validate token by send it to the server, need to get an entity object instead
  getUserByToken = () => {
    return WebApiService.makeRequest(Config.STATUS_UPDATE_URL, null, 'POST');
  }

  // Get token from URL and update WebApiService
  getAndSetTokenFromUrl = () => {
    return new Promise((resolve, reject) => {
      let token = window.location.pathname.substring(1, window.location.pathname.length); // remove first slash
      if (token && token.length > 5) {
        resolve(WebApiService.setToken(token));
        return;
      }
      console.error('No Token!');
      reject();
    });
  }

  updateStreamingStatus = (update) => {
    let newState = Object.assign({}, this.state);
    if (update.streamingState.isMute !== undefined) newState.isMute = update.streamingState.isMute;
    if (update.streamingState.isStreaming !== undefined) newState.isStreaming = update.streamingState.isStreaming;
    if (update.streamingState.isLoading !== undefined) newState.isLoading = update.streamingState.isLoading;
    if (update.streamingState.error !== undefined) newState.error = update.streamingState.error;

    console.log('newState: ', newState);
    this.setState(newState);
  }

  // Get user location from navigator, set as component state
  // Call this.sendStatus every LOCATION_INTERVAL_TIMEOUT with interval
  updatePosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        if (!this.isNullPoint(position.coords)) {
          console.log('state', this.state);
          this.setState({
            userLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy
            }
          });
          resolve()
        }
      }, (e) => {
        console.error(e);
        reject(e);
      });
    })
  }

  watchLocation() {
    if (!navigator.geolocation) {
      // Browser doesn't support Geolocation
      return this.setState({
        error: Config.ErrorTypes.Location_Permissions
      })
    };
    navigator.geolocation.watchPosition((position) => {
      if (this.isNullPoint(position)) return;
      // Parse position object
      if (position.coords.latitude) position = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
      this.setState({
        userLocation: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }
      });
    }, (error) => {
      return this.setState({
        error: Config.ErrorTypes.Location_Permissions
      });
    }, {
        enableHighAccuracy: true
      });
  }

  // Return true if the point are undefiend or equals to 0!
  isNullPoint = (position) => {
    return !(position && position.latitude && position.longitude && position.latitude !== 0 &&
      position.longitude !== 0)
  }
}

export default App;
