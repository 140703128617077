import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = props => ({
    colorPrimary: {
        backgroundColor: '#17FFEF',
    },
    barColorPrimary: {
        backgroundColor: '#676666',
    }
});

class LinearDeterminate extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className="loader">
                <LinearProgress
                    classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(LinearDeterminate);
