import React from 'react';

import { get } from 'lodash';
import debug from 'debug';
import WebApiService from '../../services/web-api.service';
import Config from '../../services/config';

let streamDebug = debug('stream');

const RECONNECT_ATTEMPT_MIN_TIME = 3 * 1000;
const STREAMING_URL = '/stream/connect';
const APPLICATION_ID = 'GlobeKeeper';
let clientRegisterToken = '';
const LOCAL_MEDIA_DIV_ID = 'myMic';
const SUBSCRIBERS_DIV_ID = 'subscribers';
const WIDTH = '100vw';
const HEIGHT = '100vh';
const $globekeeper_color = '#1c2733';
const VIDEO_WIDTH = 600;
const VIDEO_HEIGHT = 600;

// Nice method for enabling liveswitch here.
const fm = window.fm;
// Log to console and the DOM.
fm.liveswitch.Log.registerProvider(new fm.liveswitch.ConsoleLogProvider(fm.liveswitch.LogLevel.Debug));

export class GkStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.entity,
            userLocation: props.userLocation,
            isStreaming: false,
            isMute: false
        };
        this.render();
        this.channel = null;
        this.localMedia = null;
        this.layoutManager = null;
        this.videoLayout = null;
        this.audioOnly = false;
        this.receiveOnly = false;
        this.gatewayUrl = Config.GATEWAY_URL;
        this.region = ''.trim();
        const splittedServers = Config.ICE_SERVERS.split(';');
        const stunServer = splittedServers[0];
        const turnServer = splittedServers[1];
        const turnUserName = splittedServers[2];
        const turnPassword = splittedServers[3];

        this.iceServers = [
            new fm.liveswitch.IceServer('stun:' + stunServer),
            new fm.liveswitch.IceServer('turn:' + turnServer, turnUserName, turnPassword)
        ];

        // Track whether the user has decided to leave (unregister)
        // If they have not and the client gets into the Disconnected state then we attempt to reregister (reconnect) automatically.
        this.unRegistering = false;
        this.reRegisterBackoff = 200;
        this.maxRegisterBackoff = 60000;
        this.applicationId = 'my-app-id';
        this.channelId = null; // set by index.ts
        this.userId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, '');
        this.deviceId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, '');
        this.userName = null;
        this.mcuViewId = null;
        this.dataChannelsSupported = true;
        this.client = null;
        this.dataChannels = [];

        this.startPublishing();
    }

    render() {
        return <div className="app-stream">
            <section className='open-mic-div streaming-container'>
                <div className='mic-records' layout="row" layout-align="start center">
                    <div id='myMic'></div>
                    <div id="subscribers"></div>
                </div>
            </section>
        </div>

    }

    // Streaming Proccess
    startPublishing(video = true) {
        this.connect().then((j) => {
            let localMediaView = this.addLocalMediaView();
            //start local media and publisher operations
            this.startLocalMedia(localMediaView, VIDEO_HEIGHT, VIDEO_WIDTH, 15, video).then(
                () => {
                    this.setState({ isStreaming: true });
                    this.props.updateParentStreamStatus({ isStreaming: true, isMute: false, isLoading: false });
                    //show the local media view
                    setTimeout(() => {
                        if (localMediaView != null) {
                            localMediaView.style.visibility = "visible";
                        }
                        this.publisher = localMediaView;
                        if (this.state.entity) {
                            if (!this.state.entity.extras || !this.state.entity.extras.picture) {
                                localMediaView.style.background =
                                    $globekeeper_color + "url('img/streaming_avatar.svg') center bottom no-repeat";
                                localMediaView.style.backgroundSize = "45%";
                            } else {
                                localMediaView.style.background =
                                    $globekeeper_color + ` url('${this.state.entity.extras.picture}') center no-repeat`;
                            }
                        }

                        if (this.isConnected()) {
                            this.openSfuUpstreamConnection();
                        }
                    }, 0);
                }).fail((ex) => {
                    if (ex.message === 'Permission denied') {
                        this.props.updateParentStreamStatus({
                            isLoading: false,
                            isStreaming: false,
                            error: Config.ErrorTypes.Camera_Permissions
                        });
                    };
                    //hide the local media view
                    if (localMediaView != null)
                        localMediaView.style.visibility = "hidden";
                    streamDebug('There was an ERROR starting local media: ' + ex);
                });
        }).fail((ex) => {
            // this.props.updateParentStreamStatus({
            //     isLoading: false,
            //     isStreaming: false,
            //     error: Config.ErrorTypes.Camera_Permissions
            // });
            return streamDebug("There was a problem starting publishing: ", ex);
        });
    }

    connect() {
        if (this.connectingPromise) {
            return this.connectingPromise;
        }
        if (this.isConnected()) {
            return fm.liveswitch.Promise.resolveNow();
        }
        this.connectingPromise = new fm.liveswitch.Promise();

        // Create a client to manage the channel.
        this.createUser();
        // using loadash 'get' function here
        let clientId = get(this.fmClient, '_id');
        let clientDeviceId = get(this.fmClient, '_deviceId');
        this.streamConnect(this.state.userLocation, clientId, clientDeviceId).then((response) => {
            clientRegisterToken = get(response, 'data.token');
            this.joinAsync(this.incomingMessage, this.peerLeft, this.peerJoined).then((j) => {
                setTimeout(() => {
                    let tempPromise = this.connectingPromise;
                    this.connectingPromise = null;
                    return tempPromise.resolve(null);
                }, 0);
            }, (e) => {
                this.errorHandling(e);
                console.log(e);
            });
        }, (error) => {
            setTimeout(() => {
                console.log('streamingError');
                setTimeout(() => {
                    this.connect();
                }, RECONNECT_ATTEMPT_MIN_TIME);
                let tempPromise = this.connectingPromise;
                this.connectingPromise = null;
                return tempPromise.reject();
            }, 0);
        });
        return this.connectingPromise;
    }

    createUser() {
        //Guid
        let deviceId = fm.liveswitch.Guid.newGuid().toString().replace(/-/g, ''); //"web";
        streamDebug("Joining LiveSwitch Gateway: %s", this.gatewayUrl);
        this.fmClient = new fm.liveswitch.Client(this.gatewayUrl, APPLICATION_ID, this.state.entity._id,
            deviceId);
    }

    startLocalMedia(videoContainer, height, width, framesPerSecond, video = false) {
        streamDebug('Starting Local Media...');
        this.props.updateParentStreamStatus({
            isLoading: true
        });
        let promise = new fm.liveswitch.Promise();
        try {
            if (this.localMedia) {
                throw new Error('Local media has already been started.');
            }
            let pluginConfig = new fm.liveswitch.PluginConfig();
            pluginConfig.setActiveXPath("./FM.LiveSwitch.ActiveX.cab");
            // Set up the layout manager.
            let layoutManager = new fm.liveswitch.DomLayoutManager(videoContainer);
            // Constructor of LocalMedia(Boolean audio, Boolean video)
            let video = {
                width: 320,
                height: 240
            };
 
            let aDevId, vDevId;
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                aDevId = devices.find((d) => { return d.kind === 'audioinput' }).deviceId
                vDevId = devices.find((d) => { return d.kind === 'videoinput' }).deviceId

                let video = {deviceId: {exact: vDevId}};
                let audio = {deviceId: {exact: aDevId}};

                this.localMedia = new fm.liveswitch.LocalMedia(audio, video);
                this.localMedia.start().then((o) => {
                    setTimeout(() => {
                        // or maybe what Camera function that will update parent state?
                        // Kombina toggle the camera after init - remove it!
                        this.cameraToggled();
                        // Kombina toggle the camera after init - remove it!
                        streamDebug('Local Media has been started');
                        let localView = this.localMedia.getView();
                        if (localView != null) {
                            //set scale to cover
                            this.localMedia.getViewSink().setViewScale(fm.liveswitch.LayoutScale.Cover);
                            // Add the local view to the layout.
                            layoutManager.setLocalView(localView);
                        }
                        this.localMedia.setVideoMuted(false);
                        this.localMedia.setAudioMuted(false);

                        promise.resolve(this.localMedia);
                    }, 0);
                }).fail((ex) => {
                    console.log('streaming error: ', ex);
                    if (ex.message === 'Permission denied') {
                        this.props.updateParentStreamStatus({
                            isLoading: false,
                            isStreaming: false,
                            error: Config.ErrorTypes.Camera_Permissions
                        });
                    };
                    promise.reject(ex)
                });
            });
        } catch (ex) {
            promise.reject(ex);
        }

        return promise;
    }

    // Streaming Utils
    joinAsync(incomingMessage, peerLeft, peerJoined) {
        let promise = new fm.liveswitch.Promise();
        this.unRegistering = false;
        // After change team
        if (!this.fmClient) this.createUser();
        this.fmClient.addOnStateChange((client) => {
            if (client.getState() === fm.liveswitch.ClientState.Registering) {
                streamDebug('Client is registering...');
            } else if (client.getState() === fm.liveswitch.ClientState.Registered) {
                streamDebug('Client is registered, Application Id: ' + APPLICATION_ID,
                    ', Client Id: ' + client.getId());
            } else if (client.getState() === fm.liveswitch.ClientState.Unregistering) {
                streamDebug('Client is unregistering');
            } else if (client.getState() === fm.liveswitch.ClientState.Unregistered) {
                streamDebug('Client is unregistered');
            }
        });

        // Use the optional tag field to indicate our mode.
        this.fmClient.setTag('GlobeKeeperWeb');
        this.fmClient.setUserAlias('Gady');

        this.fmClient.register(clientRegisterToken).then((channels) => {
            for (let i = channels.length - 1; i >= 0; i--) {
                streamDebug('Client registered to: %s', channels[i].getId());
            }
            this.onClientRegistered(channels, incomingMessage);
            promise.resolve(null);
        }).fail((ex) => {
            this.errorHandling(ex);
            console.error("Client to register on the LiveSwitch Server: " + ex);
            this.connectingPromise = null;
            this.camOpen = false;
            promise.reject(ex);
        });

        return promise;
    }

    onClientRegistered(channels, incomingMessage) {
        this.channel = this.fmClient.getChannels()[0];

        // Monitor the channel peer connection offers.
        this.channel.addOnPeerConnectionOffer((peerConnectionOffer) => {
            // Accept the peer connection offer.
            this.openPeerAnswerConnection(peerConnectionOffer);
        });

        this.channel.addOnMessage((client, message) => {
            if (incomingMessage === null)
                return;
            if (client.getUserId() !== this.state.entity._id) {
                let clientInfo = client.getUserAlias() != null ? client.getUserAlias() : client.getUserId();
                incomingMessage(clientInfo, message);
            }
        });
    }

    openSfuUpstreamConnection(tag) {
        console.info("Opening SFU Upstream connection");
        let audioStream = new fm.liveswitch.AudioStream(this.localMedia);
        let videoStream = new fm.liveswitch.VideoStream(this.localMedia);
        let connection = this.channel.createSfuUpstreamConnection(audioStream, videoStream);
        // Tag the connection (optional).
        if (tag === null) {
            tag = 'sfu-upstream';
        }
        connection.setTag(tag);

        // Configure the connection.
        connection.setIceServers(this.iceServers);

        // Monitor the connection state changes.
        connection.addOnStateChange((connection) => {
            console.info("SFU upstream connection state is " + new fm.liveswitch.ConnectionStateWrapper(
                connection.getState()).toString() +
                ", Connection Id: " + connection.getId());

            // Cleanup if the connection closes or fails.
            if (connection.getState() === fm.liveswitch.ConnectionState.Closing || connection.getState() ===
                fm.liveswitch.ConnectionState.Failing) {
                if (connection.getRemoteClosed()) {
                    console.info("Media server closed the upstream connection for Connection Id: " +
                        connection.getId());
                }
                this.sfuUpstreamConnection = null;
            } else if (connection.getState() === fm.liveswitch.ConnectionState.Failed) {
                // Note: no need to close the connection as it's done for us.
                this.openSfuUpstreamConnection(tag);
            }
        });
        // Open the connection.
        connection.open();

        this.sfuUpstreamConnection = connection;
        return connection;
    }

    addLocalMediaView() {
        let parentDiv = document.getElementById(SUBSCRIBERS_DIV_ID);
        //check if local media div exists, if not then create it
        let localMediaDiv = document.getElementById(LOCAL_MEDIA_DIV_ID);
        // Create a div for the publisher to replace
        if (localMediaDiv === null) localMediaDiv = document.createElement('div');

        localMediaDiv.setAttribute('id', LOCAL_MEDIA_DIV_ID);
        localMediaDiv.style.height = HEIGHT;
        localMediaDiv.style.width = WIDTH;
        localMediaDiv.style.backgroundColor = "black";
        localMediaDiv.className = '';
        parentDiv.appendChild(localMediaDiv);

        return localMediaDiv;
    }

    stopLocalMedia() {
        console.info("Stopping Local Media");
        let promise = new fm.liveswitch.Promise();
        try {
            if (!this.localMedia) {
                promise.resolve(null);
                return promise;
            }
            this.localMedia.stop().then((o) => {
                console.info("Local Media has been stopped");

                // Tear down the layout manager.
                if (this.layoutManager != null) {
                    this.layoutManager.removeRemoteViews();
                    this.layoutManager.unsetLocalView();
                    this.layoutManager = null;
                }
                // Tear down the local media.
                this.localMedia = null;
                promise.resolve(null);
            })
                .fail((ex) => {
                    promise.reject(ex);
                });
        } catch (ex) {
            promise.reject(ex);
        }

        return promise;
    }

    isConnected() {
        return this.fmClient && this.fmClient.getState() === fm.liveswitch.ClientState.Registered;
    }

    streamConnect(coords, clientId, clientDeviceId) {
        return WebApiService.makeRequest(STREAMING_URL, {
            userLocation: coords,
            silent: true,
            clientId,
            clientDeviceId
        }, 'POST');
    }

    errorHandling(options) {
        this.props.updateParentStreamStatus({ isStreaming: false, isLoading: false, error: Config.ErrorTypes.Bad_Token });
    }

    // Buttons actions
    handleMute = (remoteConnectionId) => {
        let newMuteStatus = null;
        let currentMuteStatus = null;

        currentMuteStatus = this.localMedia.getAudioMuted();
        newMuteStatus = !currentMuteStatus;
        this.localMedia.setAudioMuted(newMuteStatus)

        this.setState({ isMute: newMuteStatus })
    }

    stopPublishing() {
        if (this.isStoppingPublish) {
            return;
        }
        this.isStoppingPublish = true;
        this.setState({ isStreaming: false });
        this.props.updateParentStreamStatus({ isStreaming: false, isLoading: false })
        this.stopLocalMedia().then(() => {
            //stop sfu upstream
            if (this.sfuUpstreamConnection != null) {
                this.sfuUpstreamConnection.close().then(() => {
                    this.sfuUpstreamConnection = null;
                });
            }

            //remove the local media div
            let localMediaDiv = document.getElementById(LOCAL_MEDIA_DIV_ID);
            if (localMediaDiv !== null) {
                localMediaDiv.remove();
            }

            this.isStoppingPublish = false;
            this.publisher = null;
            this.showButtons = true;
        }).fail((ex) => {
            console.error("There was a problem stopping the local media: " + ex)
        });
    }

    streamToggled = () => {
        if (this.state.isStreaming) {
            return this.stopPublishing();
        }
        this.startPublishing();
    }

    cameraToggled = () => {
        let cameraInUse = this.localMedia.getVideoSourceInput();
        if (cameraInUse.getName().indexOf('back') !== -1) {
            // this.stopCamera().then(() => {
            this.changeCameras();
            return;
            // });
        };
        this.changeCameras();
    }

    changeCameras = () => {
        let cameraInUse = this.localMedia.getVideoSourceInput();
        this.localMedia.getVideoSourceInputs().then((cameras) => {
            if (cameras.length === 1) return console.log('Only one camera');
            for (let i = 0; i < cameras.length; i++) {
                if (cameraInUse.getName() !== cameras[i].getName()) {
                    this.localMedia.changeVideoSourceInput(cameras[i]);
                    return;
                }
                console.log('cameras changed');
            }
        }, (e) => {
            console.log(e);
        });
    }

    flashToggled = () => {
        //let there be light!
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const cameras = devices.filter((device) => device.kind === 'videoinput');
            if (cameras.length === 0) {
                console.error('No camera found on this device.');
            }
            const camera = cameras[cameras.length - 1];

            // Create stream and get video track
            navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: camera.deviceId
                }
            }).then(stream => {
                const track = stream.getVideoTracks()[0];
                //Create image capture object and get camera capabilities
                const imageCapture = new ImageCapture(track)
                imageCapture.getPhotoCapabilities().then((data) => {
                    track.applyConstraints({
                        advanced: [{ torch: this.props.buttonsState.isFlash }]
                    });
                });
            });
        });
    }

    stopCamera = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.enumerateDevices().then(devices => {
                const cameras = devices.filter((device) => device.kind === 'videoinput');
                if (cameras.length === 0) {
                    console.error('No camera found on this device.');
                }
                const camera = cameras[cameras.length - 1];

                // Create stream and get video track
                navigator.mediaDevices.getUserMedia({
                    video: {
                        deviceId: camera.deviceId
                    }
                }).then(stream => {
                    stream.getTracks().forEach(track => track.stop());
                    resolve();
                }, () => {
                    reject();
                });
            });
        })
    }
}