
import React from 'react';
import './gk-stream-buttons.scss';
import {
    isAndroid,
    mobileVendor
} from 'react-device-detect';

import Config from '../../services/config';
import { GkButton } from '../GkButton/gk-button.component';
import { Grid } from '@material-ui/core';
import { GkSpinner } from '../GkSpinner/gk-spinner.component';

const UNSUPPORTED_FLASH_DEVICES = ['Huawei', 'Samsung'];
const backgroundStyle = {
    background: `url(${Config.ImagesPath + 'Combined_Shape.svg'})`,
    backgroundPosition: 'top'
};

export class GkStreamButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.entity,
            userLocation: props.userLocation
        };
        this.flashSupportTest();
        this.render();
    }

    render() {
        return (
            <Grid className="buttons-cotainer" style={backgroundStyle} container direction="row">
                {/* Mute button */}
                {!this.props.buttonsState.isStreaming || this.props.error !== null ? null : <GkButton buttonType={Config.ButtonTypes.Mute} onButtonClick={this.props.toggleMute} value={this.props.buttonsState.isMute} styleClass="mute-button" />}
                {/* play/pause camera button */}
                <div className="play-button-border">
                    <div className={`play-button ${this.props.buttonsState.isStreaming ? 'is-playing' : ''}`}
                        onClick={this.props.toggleStream} ></div>
                    {this.props.buttonsState.isLoading ? <GkSpinner /> : null}
                </div>
                {/* front/rear camera button */}
                {!this.props.buttonsState.isStreaming || this.props.error !== null ? null : <GkButton buttonType={Config.ButtonTypes.Camera} onButtonClick={this.props.toggleCamera} value={this.props.buttonsState.isRearCamera} styleClass="camera-button" />}
                {/* flash light button */}
                {!isAndroid || this.isDisabledFlashDevice || !this.props.buttonsState.isStreaming || !this.props.buttonsState.isRearCamera ? null : <GkButton buttonType={Config.ButtonTypes.Flash} onButtonClick={this.props.toggleFlash} value={this.props.buttonsState.isFlash} styleClass="flash-button" />}
            </Grid>
        );
    }

    flashSupportTest = () => {
        for (let i = 0; i < UNSUPPORTED_FLASH_DEVICES.length; i++) {
            if (mobileVendor.indexOf(UNSUPPORTED_FLASH_DEVICES[i]) !== -1) {
                return this.isDisabledFlashDevice = true;
            }
        }
    }
}