import {
    Component
} from 'react';
import Config from '../../services/config';
import WebApiService from '../../services/web-api.service';
import {
    osName,
    osVersion
} from 'react-device-detect';

const LOCATION_INTERVAL_TIMEOUT = 1000 * 10; // 10 seconds

export class GkLocationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userLocation: {
                latitude: 0,
                longitude: 0,
                accuracy: 0
            },
            battery: {},
            isStreaming: false
        };

        this.watchBattery();
        this.sendStatusInterval();
    }

    // Must have react function
    render = () => {
        return null;
    }

    // Parse entityStatus obj and send it to the server
    sendStatus = () => {
        let entityStatus = {
            location: {
                longitude: this.props.userLocation.longitude,
                latitude: this.props.userLocation.latitude,
                accuracy: this.props.userLocation.accuracy
            },
            status: this.props.isStreaming ? Config.EntityStatuses.ONLINE | Config.EntityStatuses.STREAMING_VIDEO : Config.EntityStatuses.ONLINE,
            device: osName,
            osVersion: osVersion,
            battery: {
                level: this.state.battery.level,
                charging: this.state.battery.charging
            }
        };
        console.log('Update status:', entityStatus);

        return WebApiService.makeRequest(Config.STATUS_UPDATE_URL, {
            entityStatus
        }, 'POST');
    }

    // get and parse navigator battery obj, set as component state
    watchBattery = () => {
        if (!navigator.getBattery) {
            this.setState({
                battery: undefined
            });
            return;
        }
        navigator.getBattery().then(_battery => {
            this.setState({
                battery: {
                    charging: _battery.charging,
                    level: _battery.level * 100
                }
            });

            _battery.addEventListener('chargingchange', () => {
                this.setState({
                    battery: {
                        charging: _battery.charging,
                        level: this.state.battery.level
                    }
                });
            });

            _battery.addEventListener('levelchange', () => {
                this.setState({
                    battery: {
                        charging: this.state.battery.charging,
                        level: _battery.level * 100
                    }
                });
            });
        });
    }

    sendStatusInterval = () => {
        setInterval(this.sendStatus, LOCATION_INTERVAL_TIMEOUT);
    }
}