const EntityStatuses = Object.freeze({
    NONE: 0,
    ONLINE: 1 << 1,
    LOGGED_OUT: 1 << 2,
    LOST_CONNECTION: 1 << 3,
    STREAMING_AUDIO: 1 << 4,
    STREAMING_VIDEO: 1 << 5,
    NO_LOCATION: 1 << 6,
    COMMANDER: 1 << 7,
    PANIC: 1 << 8,
    SLEEPING: 1 << 9
});

const ImagesPath = process.env.PUBLIC_URL + '/assets/images/';

const ButtonTypes = {
    Mute: 1,
    Play: 2,
    Camera: 3,
    Flash: 4
};

const ErrorTypes = {
    Bad_Token: 0,
    Location_Permissions: 1,
    Camera_Permissions: 2,
    Streaming_Stop: 3,
    Connection_Disconnected: 4
};

const Config = {
    API_URL: process.env.REACT_APP_API_URL || 'https://api.stg.globekeeper.com',
    GATEWAY_URL: process.env.REACT_APP_GATEWAY_URL || "https://ls.stg.globekeeper.com/sync",
    ICE_SERVERS: process.env.REACT_APP_ICE_SERVERS || "stun.liveswitch.fm:3478;turn.liveswitch.fm:3478;test;pa55w0rd!",
    EntityStatuses,
    STATUS_UPDATE_URL: '/integrations/token',
    ImagesPath,
    ButtonTypes,
    ErrorTypes
}

export default Config;