
import React from 'react';
import './gk-button.scss';

import Config from '../../services/config';

export class GkButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            icon: props.icon,
            isToggled: props.isToggled,
            styleClass: props.styleClass
        };
        this.render();
    }

    render() {
        return (
            <div className={"button-container " + this.state.styleClass}>
                <img src={Config.ImagesPath + this.getButtonIconByType()} className="icon" alt="icon" onClick={this.handleClick}/>
            </div>
        );
    }

    handleClick = () => {
        this.props.onButtonClick();
    }

    getButtonIconByType = () => {
        switch (this.props.buttonType) {
            case Config.ButtonTypes.Mute:
                return this.props.value ? 'volume_mute.svg' : 'volume.svg';
            case Config.ButtonTypes.Camera:
                return 'camera_selfi.svg';
            case Config.ButtonTypes.Flash:
                return this.props.value ? 'flash_off.svg' : 'flash.svg';
            default:
                return;
        }
    }
}