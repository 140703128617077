import React, { Component } from 'react';
import './gkErrorPage.scss';
import Config from '../../services/config';
import { Grid } from '@material-ui/core';

const streamingStopErrors =
    [<p key="1" className="row">Video broadcasting has stopped,</p>,
    <p key="2" className="row">but your location is still being shared</p>,
    <p key="3" className="row last-row">Press the red button to restart video broadcast</p>
    ];

const locationPermissionsError = [
    <img key="13" src={`${Config.ImagesPath}error.svg`}  alt="img" />,
    <p key="4" className="row">No Location Permissions</p>,
    <p key="5" className="row last-row">Please open permissions and retry</p>
];

const streamingPermissionsError = [
    <img key="12" src={`${Config.ImagesPath}error.svg`}  alt="img" />,
    <p key="5" className="row">No Camera Permissions</p>,
    <p key="6" className="row last-row">Please open permissions and retry</p>
];

const badTokenError = [
    <img key="14" src={`${Config.ImagesPath}error.svg`}  alt="img" />,
    <p key="8" className="row">Invalid Link</p>,
    <p key="9" className="row last-row">Please ask admin for new Link and retry</p>
];

const iosChromeError = [
    <img key="15" src={`${Config.ImagesPath}error.svg`}  alt="img" />,
    <p key="16" className="row">Chrome on iOS doesn’t support video streaming</p>,
    <p key="17" className="row last-row">Please open the link with Safari</p>
];

export class GkErrorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    };

    render() {
        return (
            <div className="error-page-container">
                <img src={`${Config.ImagesPath}lite-keeper-logo.svg`} className="error-logo" alt="logo" />

                <Grid className="grid-container" container
                    direction="column"
                    justify="flex-start"
                    alignItems="center">
                    {this.getErrorHtml()}
                </Grid>
            </div>
        )
    }

    getErrorHtml = () => {
        switch (this.props.errorType) {
            case Config.ErrorTypes.Streaming_Stop:
                return streamingStopErrors;
            case Config.ErrorTypes.Camera_Permissions:
                return streamingPermissionsError;
            case Config.ErrorTypes.Location_Permissions:
                return locationPermissionsError;
            case Config.ErrorTypes.Bad_Token:
                return badTokenError;
            case Config.ErrorTypes.Chrome_IOS_ERROR:
                return iosChromeError;
            default:
                return;
        }
    }
}