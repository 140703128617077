import React from 'react';
import { GkStream } from '../GkStream/gk-stream.component';
import { GkStreamButtons } from '../GkStreamButtons/gk-stream-buttons.component';
import { GkErrorPage } from '../gkErrorPage/gkErrorPage.component';
import Config from '../../services/config';

let afterFirstStreaming;
export class GkStreamView extends React.Component {
    constructor(props) {
        super(props);
        this.streamComponent = React.createRef();
        this.state = {
            entity: props.entity,
            userLocation: props.userLocation,
            error: null,
            buttonsState: {
                isMute: false,
                isRearCamera: true,
                isStreaming: false,
                isFlash: false,
                isLoading: true
            }
        };
        this.render();
    }

    render() {
        return (
            <div className="stream-view-container">
                {/*Stream Component*/}
                <GkStream entity={this.state.entity} updateParentStreamStatus={this.updateStreamingStatus} userLocation={this.state.userLocation} buttonsState={this.state.buttonsState} ref={this.streamComponent} />
                {/* No Stream */}
                {afterFirstStreaming && !this.state.buttonsState.isStreaming ? <GkErrorPage errorType={Config.ErrorTypes.Streaming_Stop} /> : null}
                {/*Stream Buttons*/}
                <GkStreamButtons toggleMute={this.muteToggled} toggleCamera={this.cameraToggled} toggleFlash={this.flashToggled} toggleStream={this.streamToggled} buttonsState={this.state.buttonsState} error={this.state.error} />
            </div>
        );
    }

    streamToggled = () => {
        this.streamComponent.current.streamToggled();
    };

    muteToggled = () => {
        let newButtonsState = Object.assign({}, this.state.buttonsState);
        newButtonsState.isMute = !newButtonsState.isMute;
        this.setState({ buttonsState: newButtonsState })
        this.streamComponent.current.handleMute();
    };

    cameraToggled = () => {
        let newButtonsState = Object.assign({}, this.state.buttonsState);
        newButtonsState.isRearCamera = !newButtonsState.isRearCamera;
        this.setState({ buttonsState: newButtonsState })
        this.streamComponent.current.cameraToggled();
    };

    flashToggled = () => {
        let newButtonsState = Object.assign({}, this.state.buttonsState);
        newButtonsState.isFlash = !newButtonsState.isFlash;
        this.setState({ buttonsState: newButtonsState })
        console.log('sreamview - flash state - ', this.state.buttonsState.isFlash);
        this.streamComponent.current.flashToggled();
    }

    updateStreamingStatus = (streamingState) => {
        if (!afterFirstStreaming && streamingState.isStreaming) afterFirstStreaming = true;

        let newState = Object.assign({}, this.state.buttonsState);

        if (streamingState.isMute !== undefined) newState.isMute = streamingState.isMute;
        if (streamingState.isStreaming !== undefined) newState.isStreaming = streamingState.isStreaming;
        if (streamingState.isLoading !== undefined) newState.isLoading = streamingState.isLoading;
        if (streamingState.error !== undefined) newState.error = streamingState.error;
        this.setState({
            buttonsState: newState
        });
        console.log(this.state.buttonsState.error);
        this.props.updateAppStreamStatus({ streamingState })
    }
}