import React from 'react';
import './gk-spinner.scss';

export class GkSpinner extends React.Component {
    constructor(props) {
        super(props);
        this.render();
    }

    render() {

        return (
            <div className="spinner-container">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
}